export const msText = {
  maxHeader: "You're on a hot streak!",
  errorPage: {
    referralCenter: {
      headerText: 'Something went wrong',
      message: 'We’re looking into it. Try again later',
      buttonText: 'Visit FanDuel Mohegan Sun Casino',
    },
    landingPage: {
      headerText: 'Something went wrong',
      message: 'We’re looking into it. Try again later',
      buttonText: 'Visit FanDuel Mohegan Sun Casino',
    },
  },
};
